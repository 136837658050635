html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  direction: rtl;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  overflow-y: auto;
  height: 100%;
}