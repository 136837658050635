.mainContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 30vh;
}

.pulse-logo {
  pointer-events: none;
  flex-grow: 0;
  max-width: 350px;
}

.versionBanner, .sponsorBanner {
  margin: 4px;
  margin-bottom: 10px;
  bottom: 0;
  position: fixed;
  user-select: none;
}

.versionBanner {
  right: 0;
  color: red;
  font-weight: bold;
}

.sponsorBanner {
  left: 0;
  font-style: italic;
}
