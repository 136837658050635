.fieldsContainer {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.fieldsContainer > div {
  margin: 5px;
  display: flex;
  flex: 1;
}

.textField {
  flex: 1;
}